<template>
  <div class="col-xl-9 col-lg-12 col-md-12">
    <div class="card mb-0">
      <div class="card-header">
        <router-link to="/profile/address" type="button">
          <i class="dropdown-icon si si-arrow-left"></i>
        </router-link>
        <h3 class="card-title">Adres Güncelle</h3>
        <a
          @click="removeItem()"
          type="button"
          style="position: absolute; right: 25px"
        >
          <i class="dropdown-icon si si-trash"></i>
        </a>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="form-label">Adres Başlığı</label>
              <input
                type="text"
                class="form-control"
                name="title"
                :value="address.address_title"
                placeholder="Adres Başlığı"
              />
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label class="form-label">Adres</label>
              <textarea
                type="text"
                class="form-control"
                name="address"
                placeholder="Adres"
                :value="address.address"
              ></textarea>
            </div>
          </div>
          <div class="col-sm-6 col-md-6">
            <div class="form-group">
              <label class="form-label">İl</label>
              <select
                name="city"
                id="city"
                class="form-control"
                @change="cityChange"
              >
                <optgroup label="Şehir Seçiniz">
                  <option
                    v-for="(item, i) in cityList"
                    :key="i"
                    :selected="
                      address.city == item.name
                        ? districts(item.districts)
                        : false
                    "
                    :value="item.name"
                    :data-province="JSON.stringify(item.districts)"
                  >
                    {{ item.name }}
                  </option>
                </optgroup>
              </select>
            </div>
          </div>
          <div class="col-sm-6 col-md-6">
            <div class="form-group">
              <label class="form-label">İlçe</label>
              <select name="province" id="province" class="form-control">
                <optgroup label="İlçe Seçiniz">
                  <option
                    v-for="(item, i) in selectedDistricts"
                    :key="i"
                    :selected="address.province == item.name ? true : false"
                    :value="item.name"
                  >
                    {{ item.name }}
                  </option>
                </optgroup>
              </select>
            </div>
          </div>
          <div class="col-sm-12 col-md-12"  v-if="$store.state.user_type == 'EXPERT'  || $store.state.user_type == 'CENTER'">
            <GmapMap
              ref="mapRef"
              :center="center"
              :zoom="18"
              style="width: 100%; height: 400px"
            >
              <GmapMarker
                :position="center"
                :clickable="true"
                :draggable="true"
                :icon="'https://terapivitrini.com/assets/images/marker.png'"
                @dragend="change"
              />
            </GmapMap>
            <small
              >*Mevcut konumunuzu bulabilmek için konum kullanımına izin vermeyi
              deneyin</small
            ><br />
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button
          type="button"
          @click="save"
          id="saveButton"
          class="btn btn-primary"
        >
          Kaydet
        </button>
      </div>
    </div>
  </div>
</template>
<script>
  import Swal from "sweetalert2";
export default {
  created() {
    document.title = "Adres Güncelle";
    if (this.cityList.length == 0) {
      let cityInfo = {};
      this.$store.dispatch("citysGet", cityInfo).then((value) => {
        this.cityList = value.list;
      });
    }
    this.$store
      .dispatch("addressGet", { id: this.$route.params.id })
      .then((value) => {
        this.address = value[0];
        this.center = JSON.parse(value[0].location);
      });
  },
  data() {
    return {
      showInfo: false,
      address: [],
      cityList: [],
      selectedDistricts: [],
      firstload: true,
      center: [],
      currentPlace: null,
      markers: [],
      places: [],
    };
  },
  methods: {
    change(e) {
      this.center = e.latLng;
      return this.center;
    },
    cityChange() {
      var element = document.getElementById("city");
      var option_doj =
        element.options[element.selectedIndex].getAttribute("data-province");
      this.selectedDistricts = JSON.parse(option_doj);
    },
    districts(item) {
      if (this.firstload) {
        this.firstload = false;
        this.selectedDistricts = item;
      }
      return true;
    },
    removeItem() {
      Swal.fire({
        title: "Bu adresi silmek istediğine emin misin?",
        text: "Bu işlem sonrası seçilen adres silinecek, devam etmek istiyor musun?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Devam",
        cancelButtonText: "Vazgeç",
      }).then((result) => {
        if (result.isConfirmed) {
          let phoneSave = {
            id: this.address.id,
          };
          this.$store.dispatch("addressRemove", phoneSave).then((value) => {
            if (value.type == "error") {
              if (value.message == "ERRxUAR") {
                this.$vToastify.warning(
                  "Onay sürecindeki profil güncellenemez",
                  "Uyarı!"
                );
              }
            } else {
              this.$vToastify.success(
                "Adres bilgileri başarılı bir şekilde silindi",
                "Başarılı!"
              );
              this.$router.push("/profile/address");
            }
          });
        }
      });
    },
    save() {
      let title = document.querySelector("input[name=title]").value;
      let address = document.querySelector("textarea[name=address]").value;
      let city = document.querySelector("select[name=city]").value;
      let province = document.querySelector("select[name=province]").value;
      if (title == null || title == "") {
        this.$vToastify.warning("Başlık boş bırakılamaz", "Uyarı!");
      } else if (address == null || address == "") {
        this.$vToastify.warning("Adres boş geçilemez", "Uyarı!");
      } else if (city == null || city == "") {
        this.$vToastify.warning("Şehir boş bırakılamaz", "Uyarı!");
      } else if (province == null || province == "") {
        this.$vToastify.warning("Bölge boş bırakılamaz", "Uyarı!");
      } else {
        document.getElementById("saveButton").disabled = true;
        document.getElementById("saveButton").innerHTML =
          "Bilgiler kayıt ediliyor..";
        let addressSave = {
          id: this.$route.params.id,
          title: title,
          address: address,
          city: city,
          province: province,
          location: this.center,
        };
        this.$store.dispatch("addressUpdate", addressSave).then((value) => {
          document.getElementById("saveButton").disabled = false;
          document.getElementById("saveButton").innerHTML = "Kaydet";
          if (value.type == "error") {
            if (value.message == "ERRxUAR") {
              this.$vToastify.warning(
                "Onay sürecindeki profil güncellenemez",
                "Uyarı!"
              );
            }
          } else {
            this.$vToastify.success("Adres bilgileri kaydedildi", "Başarılı!");
            this.$router.push("/profile/address");
          }
        });
      }
    },
    addMarker() {
      if (this.currentPlace) {
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };
        this.markers.push({ position: marker });
        this.places.push(this.currentPlace);
        this.center = marker;
        this.currentPlace = null;
      }
    },
    geolocate: function () {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    },
  },
  components: {},
  mounted() {
    this.geolocate();
  },
};
</script>